import React, { useEffect, useRef, useState } from 'react';
import { INDIGO_TOKEN } from '../../settings';
import { useLanguage } from '../../services/i18n/i18n.context';
import { useAccountStore, useProfileStore } from '../../store/store';
import { INDIGO_GLOBAL_VAR } from '../../constants';

interface INDIGO_VARIABLES {
  lang: string; //'EN' | 'IT';
  user_type: string; //'MERCHANT' | 'BUYER' | 'GUEST';
  vat_number: string | null;
  company: string | null;
  email: string | null;
  phone: string | null;
  [key: string]: any;
}

const IndigoWidget: React.FC = () => {
  const { language } = useLanguage();
  const {
    AccountType: accountType,
    VatCode: vat_number,
    Name: company,
  } = useProfileStore((state) => state);
  const {
    Email: email,
    PhonePrefix: phonePrefix,
    Phone: phone,
  } = useAccountStore((state) => state);
  const lang = language.substring(0, 2).toUpperCase() || 'IT';
  const [widgetOpen, setWidgetOpen] = useState(false);
  const INDIGO_SCRIPT_ID = 'opyn-indigo-script';
  const INDIGO_CONTAINER_ID = 'iaw-container';
  const INDIGO_CTA_ID = 'iaw-trigger';
  const INDIGO_CHAT_ID = 'iaw-chat';
  const profileDataRef = useRef<INDIGO_VARIABLES>({
    lang,
    user_type: accountType ? accountType : 'GUEST',
    vat_number: vat_number || null,
    company: company || null,
    email: email || null,
    phone:
      phonePrefix && phone
        ? `${phonePrefix.replace(/\D/g, '')}${phone}`
        : phone || null,
  });
  const [profileDataVersion, setProfileDataVersion] = useState<number>(0);

  const loadIndigoWidget = ({
    open = false,
    restore = false,
  }: {
    open?: boolean;
    restore?: boolean;
  }) => {
    if (restore) {
      restoreIndigo();
    }

    if ((window as any)[INDIGO_GLOBAL_VAR]) {
      return;
    }

    const script = document.createElement('script');
    const paramsEncoded = encodeURIComponent(
      Object.keys(profileDataRef.current)
        .filter(
          (key) =>
            profileDataRef.current[key] !== null &&
            profileDataRef.current[key] !== undefined,
        )
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(profileDataRef.current[key])}`,
        )
        .join('&'),
    );
    script.src = `https://app.indigo.ai/widget.js?token=${INDIGO_TOKEN(lang)}&v=2&init=init%3F${paramsEncoded}`;
    script.id = INDIGO_SCRIPT_ID;
    script.defer = true;
    script.onload = () => {
      eventHandler();
      removeCta();
      if (open || widgetOpen) {
        (window as any)[INDIGO_GLOBAL_VAR]?.setOpen(true);
      }
    };
    document.body.appendChild(script);
  };

  const eventHandler = () => {
    (window as any)[INDIGO_GLOBAL_VAR].on('open', () => setWidgetOpen(true));
    (window as any)[INDIGO_GLOBAL_VAR].on('close', () => setWidgetOpen(false));
  };

  const restoreIndigo = () => {
    const scriptElement = document.getElementById(INDIGO_SCRIPT_ID);
    const containerElement = document.getElementById(INDIGO_CONTAINER_ID);
    if (containerElement) {
      containerElement.remove();
    }
    if (scriptElement) {
      scriptElement.remove();
    }
    (window as any)[INDIGO_GLOBAL_VAR] = null;
  };

  const removeCta = (): void => {
    const ctaElement = document.getElementById(INDIGO_CTA_ID);
    const chatElement = document.getElementById(INDIGO_CHAT_ID);
    if (ctaElement) {
      ctaElement.remove();
    }
    if (chatElement) {
      chatElement.style.bottom = '0';
    }
  };

  const handleOpynInitIndigo = () => {
    loadIndigoWidget({ open: true, restore: false });
  };

  useEffect(() => {
    profileDataRef.current = {
      lang,
      user_type: accountType ? accountType : 'GUEST',
      vat_number: vat_number || null,
      company: company || null,
      email: email || null,
      phone:
        phonePrefix && phone
          ? `${phonePrefix.replace(/\D/g, '')}${phone}`
          : phone || null,
    };
    setProfileDataVersion((prevVersion) => prevVersion + 1);
  }, [lang, accountType, vat_number, company, email, phonePrefix, phone]);

  useEffect(() => {
    if ((window as any)[INDIGO_GLOBAL_VAR]) {
      loadIndigoWidget({ restore: true });
    }
  }, [profileDataVersion]);

  useEffect(() => {
    document.addEventListener('opyn-init-indigo', handleOpynInitIndigo);
    return () => {
      document.removeEventListener('opyn-init-indigo', handleOpynInitIndigo);
    };
  }, []);

  return null;
};

export default IndigoWidget;
